@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
	src: local('Material Icons'),
	local('MaterialIcons-Regular'),
	url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
	url(../fonts/MaterialIcons-Regular.woff) format('woff'),
	url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons-ico,
[class*="material-icons-"]:before {
	font-family: 'Material Icons';
	font-weight: 400;
	font-style: normal;
	font-size: inherit;
}

.material-icons-3d_rotation:before {
	content: "\e84d"
}

.material-icons-access_alarm:before {
	content: "\e190"
}

.material-icons-access_alarms:before {
	content: "\e191"
}

.material-icons-access_time:before {
	content: "\e192"
}

.material-icons-accessibility:before {
	content: "\e84e"
}

.material-icons-account_balance:before {
	content: "\e84f"
}

.material-icons-account_balance_wallet:before {
	content: "\e850"
}

.material-icons-account_box:before {
	content: "\e851"
}

.material-icons-account_circle:before {
	content: "\e853"
}

.material-icons-adb:before {
	content: "\e60e"
}

.material-icons-add:before {
	content: "\e145"
}

.material-icons-add_alarm:before {
	content: "\e193"
}

.material-icons-add_alert:before {
	content: "\e003"
}

.material-icons-add_box:before {
	content: "\e146"
}

.material-icons-add_circle:before {
	content: "\e147"
}

.material-icons-add_circle_outline:before {
	content: "\e148"
}

.material-icons-add_shopping_cart:before {
	content: "\e854"
}

.material-icons-add_to_photos:before {
	content: "\e39d"
}

.material-icons-adjust:before {
	content: "\e39e"
}

.material-icons-airline_seat_flat:before {
	content: "\e630"
}

.material-icons-airline_seat_flat_angled:before {
	content: "\e631"
}

.material-icons-airline_seat_individual_suite:before {
	content: "\e632"
}

.material-icons-airline_seat_legroom_extra:before {
	content: "\e633"
}

.material-icons-airline_seat_legroom_normal:before {
	content: "\e634"
}

.material-icons-airline_seat_legroom_reduced:before {
	content: "\e635"
}

.material-icons-airline_seat_recline_extra:before {
	content: "\e636"
}

.material-icons-airline_seat_recline_normal:before {
	content: "\e637"
}

.material-icons-airplanemode_active:before {
	content: "\e195"
}

.material-icons-airplanemode_inactive:before {
	content: "\e194"
}

.material-icons-airplay:before {
	content: "\e055"
}

.material-icons-alarm:before {
	content: "\e855"
}

.material-icons-alarm_add:before {
	content: "\e856"
}

.material-icons-alarm_off:before {
	content: "\e857"
}

.material-icons-alarm_on:before {
	content: "\e858"
}

.material-icons-album:before {
	content: "\e019"
}

.material-icons-android:before {
	content: "\e859"
}

.material-icons-announcement:before {
	content: "\e85a"
}

.material-icons-apps:before {
	content: "\e5c3"
}

.material-icons-archive:before {
	content: "\e149"
}

.material-icons-arrow_back:before {
	content: "\e5c4"
}

.material-icons-arrow_drop_down:before {
	content: "\e5c5"
}

.material-icons-arrow_drop_down_circle:before {
	content: "\e5c6"
}

.material-icons-arrow_drop_up:before {
	content: "\e5c7"
}

.material-icons-arrow_forward:before {
	content: "\e5c8"
}

.material-icons-aspect_ratio:before {
	content: "\e85b"
}

.material-icons-assessment:before {
	content: "\e85c"
}

.material-icons-assignment:before {
	content: "\e85d"
}

.material-icons-assignment_ind:before {
	content: "\e85e"
}

.material-icons-assignment_late:before {
	content: "\e85f"
}

.material-icons-assignment_return:before {
	content: "\e860"
}

.material-icons-assignment_returned:before {
	content: "\e861"
}

.material-icons-assignment_turned_in:before {
	content: "\e862"
}

.material-icons-assistant:before {
	content: "\e39f"
}

.material-icons-assistant_photo:before {
	content: "\e3a0"
}

.material-icons-attach_file:before {
	content: "\e226"
}

.material-icons-attach_money:before {
	content: "\e227"
}

.material-icons-attachment:before {
	content: "\e2bc"
}

.material-icons-audiotrack:before {
	content: "\e3a1"
}

.material-icons-autorenew:before {
	content: "\e863"
}

.material-icons-av_timer:before {
	content: "\e01b"
}

.material-icons-backspace:before {
	content: "\e14a"
}

.material-icons-backup:before {
	content: "\e864"
}

.material-icons-battery_alert:before {
	content: "\e19c"
}

.material-icons-battery_charging_full:before {
	content: "\e1a3"
}

.material-icons-battery_full:before {
	content: "\e1a4"
}

.material-icons-battery_std:before {
	content: "\e1a5"
}

.material-icons-battery_unknown:before {
	content: "\e1a6"
}

.material-icons-beenhere:before {
	content: "\e52d"
}

.material-icons-block:before {
	content: "\e14b"
}

.material-icons-bluetooth:before {
	content: "\e1a7"
}

.material-icons-bluetooth_audio:before {
	content: "\e60f"
}

.material-icons-bluetooth_connected:before {
	content: "\e1a8"
}

.material-icons-bluetooth_disabled:before {
	content: "\e1a9"
}

.material-icons-bluetooth_searching:before {
	content: "\e1aa"
}

.material-icons-blur_circular:before {
	content: "\e3a2"
}

.material-icons-blur_linear:before {
	content: "\e3a3"
}

.material-icons-blur_off:before {
	content: "\e3a4"
}

.material-icons-blur_on:before {
	content: "\e3a5"
}

.material-icons-book:before {
	content: "\e865"
}

.material-icons-bookmark:before {
	content: "\e866"
}

.material-icons-bookmark_border:before {
	content: "\e867"
}

.material-icons-border_all:before {
	content: "\e228"
}

.material-icons-border_bottom:before {
	content: "\e229"
}

.material-icons-border_clear:before {
	content: "\e22a"
}

.material-icons-border_color:before {
	content: "\e22b"
}

.material-icons-border_horizontal:before {
	content: "\e22c"
}

.material-icons-border_inner:before {
	content: "\e22d"
}

.material-icons-border_left:before {
	content: "\e22e"
}

.material-icons-border_outer:before {
	content: "\e22f"
}

.material-icons-border_right:before {
	content: "\e230"
}

.material-icons-border_style:before {
	content: "\e231"
}

.material-icons-border_top:before {
	content: "\e232"
}

.material-icons-border_vertical:before {
	content: "\e233"
}

.material-icons-brightness_1:before {
	content: "\e3a6"
}

.material-icons-brightness_2:before {
	content: "\e3a7"
}

.material-icons-brightness_3:before {
	content: "\e3a8"
}

.material-icons-brightness_4:before {
	content: "\e3a9"
}

.material-icons-brightness_5:before {
	content: "\e3aa"
}

.material-icons-brightness_6:before {
	content: "\e3ab"
}

.material-icons-brightness_7:before {
	content: "\e3ac"
}

.material-icons-brightness_auto:before {
	content: "\e1ab"
}

.material-icons-brightness_high:before {
	content: "\e1ac"
}

.material-icons-brightness_low:before {
	content: "\e1ad"
}

.material-icons-brightness_medium:before {
	content: "\e1ae"
}

.material-icons-broken_image:before {
	content: "\e3ad"
}

.material-icons-brush:before {
	content: "\e3ae"
}

.material-icons-bug_report:before {
	content: "\e868"
}

.material-icons-build:before {
	content: "\e869"
}

.material-icons-business:before {
	content: "\e0af"
}

.material-icons-cached:before {
	content: "\e86a"
}

.material-icons-cake:before {
	content: "\e7e9"
}

.material-icons-call:before {
	content: "\e0b0"
}

.material-icons-call_end:before {
	content: "\e0b1"
}

.material-icons-call_made:before {
	content: "\e0b2"
}

.material-icons-call_merge:before {
	content: "\e0b3"
}

.material-icons-call_missed:before {
	content: "\e0b4"
}

.material-icons-call_received:before {
	content: "\e0b5"
}

.material-icons-call_split:before {
	content: "\e0b6"
}

.material-icons-camera:before {
	content: "\e3af"
}

.material-icons-camera_alt:before {
	content: "\e3b0"
}

.material-icons-camera_enhance:before {
	content: "\e8fc"
}

.material-icons-camera_front:before {
	content: "\e3b1"
}

.material-icons-camera_rear:before {
	content: "\e3b2"
}

.material-icons-camera_roll:before {
	content: "\e3b3"
}

.material-icons-cancel:before {
	content: "\e5c9"
}

.material-icons-card_giftcard:before {
	content: "\e8f6"
}

.material-icons-card_membership:before {
	content: "\e8f7"
}

.material-icons-card_travel:before {
	content: "\e8f8"
}

.material-icons-cast:before {
	content: "\e307"
}

.material-icons-cast_connected:before {
	content: "\e308"
}

.material-icons-center_focus_strong:before {
	content: "\e3b4"
}

.material-icons-center_focus_weak:before {
	content: "\e3b5"
}

.material-icons-change_history:before {
	content: "\e86b"
}

.material-icons-chat:before {
	content: "\e0b7"
}

.material-icons-chat_bubble:before {
	content: "\e0ca"
}

.material-icons-chat_bubble_outline:before {
	content: "\e0cb"
}

.material-icons-check:before {
	content: "\e5ca"
}

.material-icons-check_box:before {
	content: "\e834"
}

.material-icons-check_box_outline_blank:before {
	content: "\e835"
}

.material-icons-check_circle:before {
	content: "\e86c"
}

.material-icons-chevron_left:before {
	content: "\e5cb"
}

.material-icons-chevron_right:before {
	content: "\e5cc"
}

.material-icons-chrome_reader_mode:before {
	content: "\e86d"
}

.material-icons-class:before {
	content: "\e86e"
}

.material-icons-clear:before {
	content: "\e14c"
}

.material-icons-clear_all:before {
	content: "\e0b8"
}

.material-icons-close:before {
	content: "\e5cd"
}

.material-icons-closed_caption:before {
	content: "\e01c"
}

.material-icons-cloud:before {
	content: "\e2bd"
}

.material-icons-cloud_circle:before {
	content: "\e2be"
}

.material-icons-cloud_done:before {
	content: "\e2bf"
}

.material-icons-cloud_download:before {
	content: "\e2c0"
}

.material-icons-cloud_off:before {
	content: "\e2c1"
}

.material-icons-cloud_queue:before {
	content: "\e2c2"
}

.material-icons-cloud_upload:before {
	content: "\e2c3"
}

.material-icons-code:before {
	content: "\e86f"
}

.material-icons-collections:before {
	content: "\e3b6"
}

.material-icons-collections_bookmark:before {
	content: "\e431"
}

.material-icons-color_lens:before {
	content: "\e3b7"
}

.material-icons-colorize:before {
	content: "\e3b8"
}

.material-icons-comment:before {
	content: "\e0b9"
}

.material-icons-compare:before {
	content: "\e3b9"
}

.material-icons-computer:before {
	content: "\e30a"
}

.material-icons-confirmation_number:before {
	content: "\e638"
}

.material-icons-contact_phone:before {
	content: "\e0cf"
}

.material-icons-contacts:before {
	content: "\e0ba"
}

.material-icons-content_copy:before {
	content: "\e14d"
}

.material-icons-content_cut:before {
	content: "\e14e"
}

.material-icons-content_paste:before {
	content: "\e14f"
}

.material-icons-control_point:before {
	content: "\e3ba"
}

.material-icons-control_point_duplicate:before {
	content: "\e3bb"
}

.material-icons-create:before {
	content: "\e150"
}

.material-icons-credit_card:before {
	content: "\e870"
}

.material-icons-crop:before {
	content: "\e3be"
}

.material-icons-crop_16_9:before {
	content: "\e3bc"
}

.material-icons-crop_3_2:before {
	content: "\e3bd"
}

.material-icons-crop_5_4:before {
	content: "\e3bf"
}

.material-icons-crop_7_5:before {
	content: "\e3c0"
}

.material-icons-crop_din:before {
	content: "\e3c1"
}

.material-icons-crop_free:before {
	content: "\e3c2"
}

.material-icons-crop_landscape:before {
	content: "\e3c3"
}

.material-icons-crop_original:before {
	content: "\e3c4"
}

.material-icons-crop_portrait:before {
	content: "\e3c5"
}

.material-icons-crop_square:before {
	content: "\e3c6"
}

.material-icons-dashboard:before {
	content: "\e871"
}

.material-icons-data_usage:before {
	content: "\e1af"
}

.material-icons-dehaze:before {
	content: "\e3c7"
}

.material-icons-delete:before {
	content: "\e872"
}

.material-icons-description:before {
	content: "\e873"
}

.material-icons-desktop_mac:before {
	content: "\e30b"
}

.material-icons-desktop_windows:before {
	content: "\e30c"
}

.material-icons-details:before {
	content: "\e3c8"
}

.material-icons-developer_board:before {
	content: "\e30d"
}

.material-icons-developer_mode:before {
	content: "\e1b0"
}

.material-icons-device_hub:before {
	content: "\e335"
}

.material-icons-devices:before {
	content: "\e1b1"
}

.material-icons-dialer_sip:before {
	content: "\e0bb"
}

.material-icons-dialpad:before {
	content: "\e0bc"
}

.material-icons-directions:before {
	content: "\e52e"
}

.material-icons-directions_bike:before {
	content: "\e52f"
}

.material-icons-directions_boat:before {
	content: "\e532"
}

.material-icons-directions_bus:before {
	content: "\e530"
}

.material-icons-directions_car:before {
	content: "\e531"
}

.material-icons-directions_railway:before {
	content: "\e534"
}

.material-icons-directions_run:before {
	content: "\e566"
}

.material-icons-directions_subway:before {
	content: "\e533"
}

.material-icons-directions_transit:before {
	content: "\e535"
}

.material-icons-directions_walk:before {
	content: "\e536"
}

.material-icons-disc_full:before {
	content: "\e610"
}

.material-icons-dns:before {
	content: "\e875"
}

.material-icons-do_not_disturb:before {
	content: "\e612"
}

.material-icons-do_not_disturb_alt:before {
	content: "\e611"
}

.material-icons-dock:before {
	content: "\e30e"
}

.material-icons-domain:before {
	content: "\e7ee"
}

.material-icons-done:before {
	content: "\e876"
}

.material-icons-done_all:before {
	content: "\e877"
}

.material-icons-drafts:before {
	content: "\e151"
}

.material-icons-drive_eta:before {
	content: "\e613"
}

.material-icons-dvr:before {
	content: "\e1b2"
}

.material-icons-edit:before {
	content: "\e3c9"
}

.material-icons-eject:before {
	content: "\e8fb"
}

.material-icons-email:before {
	content: "\e0be"
}

.material-icons-equalizer:before {
	content: "\e01d"
}

.material-icons-error:before {
	content: "\e000"
}

.material-icons-error_outline:before {
	content: "\e001"
}

.material-icons-event:before {
	content: "\e878"
}

.material-icons-event_available:before {
	content: "\e614"
}

.material-icons-event_busy:before {
	content: "\e615"
}

.material-icons-event_note:before {
	content: "\e616"
}

.material-icons-event_seat:before {
	content: "\e903"
}

.material-icons-exit_to_app:before {
	content: "\e879"
}

.material-icons-expand_less:before {
	content: "\e5ce"
}

.material-icons-expand_more:before {
	content: "\e5cf"
}

.material-icons-explicit:before {
	content: "\e01e"
}

.material-icons-explore:before {
	content: "\e87a"
}

.material-icons-exposure:before {
	content: "\e3ca"
}

.material-icons-exposure_neg_1:before {
	content: "\e3cb"
}

.material-icons-exposure_neg_2:before {
	content: "\e3cc"
}

.material-icons-exposure_plus_1:before {
	content: "\e3cd"
}

.material-icons-exposure_plus_2:before {
	content: "\e3ce"
}

.material-icons-exposure_zero:before {
	content: "\e3cf"
}

.material-icons-extension:before {
	content: "\e87b"
}

.material-icons-face:before {
	content: "\e87c"
}

.material-icons-fast_forward:before {
	content: "\e01f"
}

.material-icons-fast_rewind:before {
	content: "\e020"
}

.material-icons-favorite:before {
	content: "\e87d"
}

.material-icons-favorite_border:before {
	content: "\e87e"
}

.material-icons-feedback:before {
	content: "\e87f"
}

.material-icons-file_download:before {
	content: "\e2c4"
}

.material-icons-file_upload:before {
	content: "\e2c6"
}

.material-icons-filter:before {
	content: "\e3d3"
}

.material-icons-filter_1:before {
	content: "\e3d0"
}

.material-icons-filter_2:before {
	content: "\e3d1"
}

.material-icons-filter_3:before {
	content: "\e3d2"
}

.material-icons-filter_4:before {
	content: "\e3d4"
}

.material-icons-filter_5:before {
	content: "\e3d5"
}

.material-icons-filter_6:before {
	content: "\e3d6"
}

.material-icons-filter_7:before {
	content: "\e3d7"
}

.material-icons-filter_8:before {
	content: "\e3d8"
}

.material-icons-filter_9:before {
	content: "\e3d9"
}

.material-icons-filter_9_plus:before {
	content: "\e3da"
}

.material-icons-filter_b_and_w:before {
	content: "\e3db"
}

.material-icons-filter_center_focus:before {
	content: "\e3dc"
}

.material-icons-filter_drama:before {
	content: "\e3dd"
}

.material-icons-filter_frames:before {
	content: "\e3de"
}

.material-icons-filter_hdr:before {
	content: "\e3df"
}

.material-icons-filter_list:before {
	content: "\e152"
}

.material-icons-filter_none:before {
	content: "\e3e0"
}

.material-icons-filter_tilt_shift:before {
	content: "\e3e2"
}

.material-icons-filter_vintage:before {
	content: "\e3e3"
}

.material-icons-find_in_page:before {
	content: "\e880"
}

.material-icons-find_replace:before {
	content: "\e881"
}

.material-icons-flag:before {
	content: "\e153"
}

.material-icons-flare:before {
	content: "\e3e4"
}

.material-icons-flash_auto:before {
	content: "\e3e5"
}

.material-icons-flash_off:before {
	content: "\e3e6"
}

.material-icons-flash_on:before {
	content: "\e3e7"
}

.material-icons-flight:before {
	content: "\e539"
}

.material-icons-flight_land:before {
	content: "\e904"
}

.material-icons-flight_takeoff:before {
	content: "\e905"
}

.material-icons-flip:before {
	content: "\e3e8"
}

.material-icons-flip_to_back:before {
	content: "\e882"
}

.material-icons-flip_to_front:before {
	content: "\e883"
}

.material-icons-folder:before {
	content: "\e2c7"
}

.material-icons-folder_open:before {
	content: "\e2c8"
}

.material-icons-folder_shared:before {
	content: "\e2c9"
}

.material-icons-folder_special:before {
	content: "\e617"
}

.material-icons-font_download:before {
	content: "\e167"
}

.material-icons-format_align_center:before {
	content: "\e234"
}

.material-icons-format_align_justify:before {
	content: "\e235"
}

.material-icons-format_align_left:before {
	content: "\e236"
}

.material-icons-format_align_right:before {
	content: "\e237"
}

.material-icons-format_bold:before {
	content: "\e238"
}

.material-icons-format_clear:before {
	content: "\e239"
}

.material-icons-format_color_fill:before {
	content: "\e23a"
}

.material-icons-format_color_reset:before {
	content: "\e23b"
}

.material-icons-format_color_text:before {
	content: "\e23c"
}

.material-icons-format_indent_decrease:before {
	content: "\e23d"
}

.material-icons-format_indent_increase:before {
	content: "\e23e"
}

.material-icons-format_italic:before {
	content: "\e23f"
}

.material-icons-format_line_spacing:before {
	content: "\e240"
}

.material-icons-format_list_bulleted:before {
	content: "\e241"
}

.material-icons-format_list_numbered:before {
	content: "\e242"
}

.material-icons-format_paint:before {
	content: "\e243"
}

.material-icons-format_quote:before {
	content: "\e244"
}

.material-icons-format_size:before {
	content: "\e245"
}

.material-icons-format_strikethrough:before {
	content: "\e246"
}

.material-icons-format_textdirection_l_to_r:before {
	content: "\e247"
}

.material-icons-format_textdirection_r_to_l:before {
	content: "\e248"
}

.material-icons-format_underlined:before {
	content: "\e249"
}

.material-icons-forum:before {
	content: "\e0bf"
}

.material-icons-forward:before {
	content: "\e154"
}

.material-icons-forward_10:before {
	content: "\e056"
}

.material-icons-forward_30:before {
	content: "\e057"
}

.material-icons-forward_5:before {
	content: "\e058"
}

.material-icons-fullscreen:before {
	content: "\e5d0"
}

.material-icons-fullscreen_exit:before {
	content: "\e5d1"
}

.material-icons-functions:before {
	content: "\e24a"
}

.material-icons-gamepad:before {
	content: "\e30f"
}

.material-icons-games:before {
	content: "\e021"
}

.material-icons-gesture:before {
	content: "\e155"
}

.material-icons-get_app:before {
	content: "\e884"
}

.material-icons-gif:before {
	content: "\e908"
}

.material-icons-gps_fixed:before {
	content: "\e1b3"
}

.material-icons-gps_not_fixed:before {
	content: "\e1b4"
}

.material-icons-gps_off:before {
	content: "\e1b5"
}

.material-icons-grade:before {
	content: "\e885"
}

.material-icons-gradient:before {
	content: "\e3e9"
}

.material-icons-grain:before {
	content: "\e3ea"
}

.material-icons-graphic_eq:before {
	content: "\e1b8"
}

.material-icons-grid_off:before {
	content: "\e3eb"
}

.material-icons-grid_on:before {
	content: "\e3ec"
}

.material-icons-group:before {
	content: "\e7ef"
}

.material-icons-group_add:before {
	content: "\e7f0"
}

.material-icons-group_work:before {
	content: "\e886"
}

.material-icons-hd:before {
	content: "\e052"
}

.material-icons-hdr_off:before {
	content: "\e3ed"
}

.material-icons-hdr_on:before {
	content: "\e3ee"
}

.material-icons-hdr_strong:before {
	content: "\e3f1"
}

.material-icons-hdr_weak:before {
	content: "\e3f2"
}

.material-icons-headset:before {
	content: "\e310"
}

.material-icons-headset_mic:before {
	content: "\e311"
}

.material-icons-healing:before {
	content: "\e3f3"
}

.material-icons-hearing:before {
	content: "\e023"
}

.material-icons-help:before {
	content: "\e887"
}

.material-icons-help_outline:before {
	content: "\e8fd"
}

.material-icons-high_quality:before {
	content: "\e024"
}

.material-icons-highlight_off:before {
	content: "\e888"
}

.material-icons-history:before {
	content: "\e889"
}

.material-icons-home:before {
	content: "\e88a"
}

.material-icons-hotel:before {
	content: "\e53a"
}

.material-icons-hourglass_empty:before {
	content: "\e88b"
}

.material-icons-hourglass_full:before {
	content: "\e88c"
}

.material-icons-http:before {
	content: "\e902"
}

.material-icons-https:before {
	content: "\e88d"
}

.material-icons-image:before {
	content: "\e3f4"
}

.material-icons-image_aspect_ratio:before {
	content: "\e3f5"
}

.material-icons-import_export:before {
	content: "\e0c3"
}

.material-icons-inbox:before {
	content: "\e156"
}

.material-icons-indeterminate_check_box:before {
	content: "\e909"
}

.material-icons-info:before {
	content: "\e88e"
}

.material-icons-info_outline:before {
	content: "\e88f"
}

.material-icons-input:before {
	content: "\e890"
}

.material-icons-insert_chart:before {
	content: "\e24b"
}

.material-icons-insert_comment:before {
	content: "\e24c"
}

.material-icons-insert_drive_file:before {
	content: "\e24d"
}

.material-icons-insert_emoticon:before {
	content: "\e24e"
}

.material-icons-insert_invitation:before {
	content: "\e24f"
}

.material-icons-insert_link:before {
	content: "\e250"
}

.material-icons-insert_photo:before {
	content: "\e251"
}

.material-icons-invert_colors:before {
	content: "\e891"
}

.material-icons-invert_colors_off:before {
	content: "\e0c4"
}

.material-icons-iso:before {
	content: "\e3f6"
}

.material-icons-keyboard:before {
	content: "\e312"
}

.material-icons-keyboard_arrow_down:before {
	content: "\e313"
}

.material-icons-keyboard_arrow_left:before {
	content: "\e314"
}

.material-icons-keyboard_arrow_right:before {
	content: "\e315"
}

.material-icons-keyboard_arrow_up:before {
	content: "\e316"
}

.material-icons-keyboard_backspace:before {
	content: "\e317"
}

.material-icons-keyboard_capslock:before {
	content: "\e318"
}

.material-icons-keyboard_hide:before {
	content: "\e31a"
}

.material-icons-keyboard_return:before {
	content: "\e31b"
}

.material-icons-keyboard_tab:before {
	content: "\e31c"
}

.material-icons-keyboard_voice:before {
	content: "\e31d"
}

.material-icons-label:before {
	content: "\e892"
}

.material-icons-label_outline:before {
	content: "\e893"
}

.material-icons-landscape:before {
	content: "\e3f7"
}

.material-icons-language:before {
	content: "\e894"
}

.material-icons-laptop:before {
	content: "\e31e"
}

.material-icons-laptop_chromebook:before {
	content: "\e31f"
}

.material-icons-laptop_mac:before {
	content: "\e320"
}

.material-icons-laptop_windows:before {
	content: "\e321"
}

.material-icons-launch:before {
	content: "\e895"
}

.material-icons-layers:before {
	content: "\e53b"
}

.material-icons-layers_clear:before {
	content: "\e53c"
}

.material-icons-leak_add:before {
	content: "\e3f8"
}

.material-icons-leak_remove:before {
	content: "\e3f9"
}

.material-icons-lens:before {
	content: "\e3fa"
}

.material-icons-library_add:before {
	content: "\e02e"
}

.material-icons-library_books:before {
	content: "\e02f"
}

.material-icons-library_music:before {
	content: "\e030"
}

.material-icons-link:before {
	content: "\e157"
}

.material-icons-list:before {
	content: "\e896"
}

.material-icons-live_help:before {
	content: "\e0c6"
}

.material-icons-live_tv:before {
	content: "\e639"
}

.material-icons-local_activity:before {
	content: "\e53f"
}

.material-icons-local_airport:before {
	content: "\e53d"
}

.material-icons-local_atm:before {
	content: "\e53e"
}

.material-icons-local_bar:before {
	content: "\e540"
}

.material-icons-local_cafe:before {
	content: "\e541"
}

.material-icons-local_car_wash:before {
	content: "\e542"
}

.material-icons-local_convenience_store:before {
	content: "\e543"
}

.material-icons-local_dining:before {
	content: "\e556"
}

.material-icons-local_drink:before {
	content: "\e544"
}

.material-icons-local_florist:before {
	content: "\e545"
}

.material-icons-local_gas_station:before {
	content: "\e546"
}

.material-icons-local_grocery_store:before {
	content: "\e547"
}

.material-icons-local_hospital:before {
	content: "\e548"
}

.material-icons-local_hotel:before {
	content: "\e549"
}

.material-icons-local_laundry_service:before {
	content: "\e54a"
}

.material-icons-local_library:before {
	content: "\e54b"
}

.material-icons-local_mall:before {
	content: "\e54c"
}

.material-icons-local_movies:before {
	content: "\e54d"
}

.material-icons-local_offer:before {
	content: "\e54e"
}

.material-icons-local_parking:before {
	content: "\e54f"
}

.material-icons-local_pharmacy:before {
	content: "\e550"
}

.material-icons-local_phone:before {
	content: "\e551"
}

.material-icons-local_pizza:before {
	content: "\e552"
}

.material-icons-local_play:before {
	content: "\e553"
}

.material-icons-local_post_office:before {
	content: "\e554"
}

.material-icons-local_printshop:before {
	content: "\e555"
}

.material-icons-local_see:before {
	content: "\e557"
}

.material-icons-local_shipping:before {
	content: "\e558"
}

.material-icons-local_taxi:before {
	content: "\e559"
}

.material-icons-location_city:before {
	content: "\e7f1"
}

.material-icons-location_disabled:before {
	content: "\e1b6"
}

.material-icons-location_off:before {
	content: "\e0c7"
}

.material-icons-location_on:before {
	content: "\e0c8"
}

.material-icons-location_searching:before {
	content: "\e1b7"
}

.material-icons-lock:before {
	content: "\e897"
}

.material-icons-lock_open:before {
	content: "\e898"
}

.material-icons-lock_outline:before {
	content: "\e899"
}

.material-icons-looks:before {
	content: "\e3fc"
}

.material-icons-looks_3:before {
	content: "\e3fb"
}

.material-icons-looks_4:before {
	content: "\e3fd"
}

.material-icons-looks_5:before {
	content: "\e3fe"
}

.material-icons-looks_6:before {
	content: "\e3ff"
}

.material-icons-looks_one:before {
	content: "\e400"
}

.material-icons-looks_two:before {
	content: "\e401"
}

.material-icons-loop:before {
	content: "\e028"
}

.material-icons-loupe:before {
	content: "\e402"
}

.material-icons-loyalty:before {
	content: "\e89a"
}

.material-icons-mail:before {
	content: "\e158"
}

.material-icons-map:before {
	content: "\e55b"
}

.material-icons-markunread:before {
	content: "\e159"
}

.material-icons-markunread_mailbox:before {
	content: "\e89b"
}

.material-icons-memory:before {
	content: "\e322"
}

.material-icons-menu:before {
	content: "\e5d2"
}

.material-icons-merge_type:before {
	content: "\e252"
}

.material-icons-message:before {
	content: "\e0c9"
}

.material-icons-mic:before {
	content: "\e029"
}

.material-icons-mic_none:before {
	content: "\e02a"
}

.material-icons-mic_off:before {
	content: "\e02b"
}

.material-icons-mms:before {
	content: "\e618"
}

.material-icons-mode_comment:before {
	content: "\e253"
}

.material-icons-mode_edit:before {
	content: "\e254"
}

.material-icons-money_off:before {
	content: "\e25c"
}

.material-icons-monochrome_photos:before {
	content: "\e403"
}

.material-icons-mood:before {
	content: "\e7f2"
}

.material-icons-mood_bad:before {
	content: "\e7f3"
}

.material-icons-more:before {
	content: "\e619"
}

.material-icons-more_horiz:before {
	content: "\e5d3"
}

.material-icons-more_vert:before {
	content: "\e5d4"
}

.material-icons-mouse:before {
	content: "\e323"
}

.material-icons-movie:before {
	content: "\e02c"
}

.material-icons-movie_creation:before {
	content: "\e404"
}

.material-icons-music_note:before {
	content: "\e405"
}

.material-icons-my_location:before {
	content: "\e55c"
}

.material-icons-nature:before {
	content: "\e406"
}

.material-icons-nature_people:before {
	content: "\e407"
}

.material-icons-navigate_before:before {
	content: "\e408"
}

.material-icons-navigate_next:before {
	content: "\e409"
}

.material-icons-navigation:before {
	content: "\e55d"
}

.material-icons-network_cell:before {
	content: "\e1b9"
}

.material-icons-network_locked:before {
	content: "\e61a"
}

.material-icons-network_wifi:before {
	content: "\e1ba"
}

.material-icons-new_releases:before {
	content: "\e031"
}

.material-icons-nfc:before {
	content: "\e1bb"
}

.material-icons-no_sim:before {
	content: "\e0cc"
}

.material-icons-not_interested:before {
	content: "\e033"
}

.material-icons-note_add:before {
	content: "\e89c"
}

.material-icons-notifications:before {
	content: "\e7f4"
}

.material-icons-notifications_active:before {
	content: "\e7f7"
}

.material-icons-notifications_none:before {
	content: "\e7f5"
}

.material-icons-notifications_off:before {
	content: "\e7f6"
}

.material-icons-notifications_paused:before {
	content: "\e7f8"
}

.material-icons-offline_pin:before {
	content: "\e90a"
}

.material-icons-ondemand_video:before {
	content: "\e63a"
}

.material-icons-open_in_browser:before {
	content: "\e89d"
}

.material-icons-open_in_new:before {
	content: "\e89e"
}

.material-icons-open_with:before {
	content: "\e89f"
}

.material-icons-pages:before {
	content: "\e7f9"
}

.material-icons-pageview:before {
	content: "\e8a0"
}

.material-icons-palette:before {
	content: "\e40a"
}

.material-icons-panorama:before {
	content: "\e40b"
}

.material-icons-panorama_fish_eye:before {
	content: "\e40c"
}

.material-icons-panorama_horizontal:before {
	content: "\e40d"
}

.material-icons-panorama_vertical:before {
	content: "\e40e"
}

.material-icons-panorama_wide_angle:before {
	content: "\e40f"
}

.material-icons-party_mode:before {
	content: "\e7fa"
}

.material-icons-pause:before {
	content: "\e034"
}

.material-icons-pause_circle_filled:before {
	content: "\e035"
}

.material-icons-pause_circle_outline:before {
	content: "\e036"
}

.material-icons-payment:before {
	content: "\e8a1"
}

.material-icons-people:before {
	content: "\e7fb"
}

.material-icons-people_outline:before {
	content: "\e7fc"
}

.material-icons-perm_camera_mic:before {
	content: "\e8a2"
}

.material-icons-perm_contact_calendar:before {
	content: "\e8a3"
}

.material-icons-perm_data_setting:before {
	content: "\e8a4"
}

.material-icons-perm_device_information:before {
	content: "\e8a5"
}

.material-icons-perm_identity:before {
	content: "\e8a6"
}

.material-icons-perm_media:before {
	content: "\e8a7"
}

.material-icons-perm_phone_msg:before {
	content: "\e8a8"
}

.material-icons-perm_scan_wifi:before {
	content: "\e8a9"
}

.material-icons-person:before {
	content: "\e7fd"
}

.material-icons-person_add:before {
	content: "\e7fe"
}

.material-icons-person_outline:before {
	content: "\e7ff"
}

.material-icons-person_pin:before {
	content: "\e55a"
}

.material-icons-personal_video:before {
	content: "\e63b"
}

.material-icons-phone:before {
	content: "\e0cd"
}

.material-icons-phone_android:before {
	content: "\e324"
}

.material-icons-phone_bluetooth_speaker:before {
	content: "\e61b"
}

.material-icons-phone_forwarded:before {
	content: "\e61c"
}

.material-icons-phone_in_talk:before {
	content: "\e61d"
}

.material-icons-phone_iphone:before {
	content: "\e325"
}

.material-icons-phone_locked:before {
	content: "\e61e"
}

.material-icons-phone_missed:before {
	content: "\e61f"
}

.material-icons-phone_paused:before {
	content: "\e620"
}

.material-icons-phonelink:before {
	content: "\e326"
}

.material-icons-phonelink_erase:before {
	content: "\e0db"
}

.material-icons-phonelink_lock:before {
	content: "\e0dc"
}

.material-icons-phonelink_off:before {
	content: "\e327"
}

.material-icons-phonelink_ring:before {
	content: "\e0dd"
}

.material-icons-phonelink_setup:before {
	content: "\e0de"
}

.material-icons-photo:before {
	content: "\e410"
}

.material-icons-photo_album:before {
	content: "\e411"
}

.material-icons-photo_camera:before {
	content: "\e412"
}

.material-icons-photo_library:before {
	content: "\e413"
}

.material-icons-photo_size_select_actual:before {
	content: "\e432"
}

.material-icons-photo_size_select_large:before {
	content: "\e433"
}

.material-icons-photo_size_select_small:before {
	content: "\e434"
}

.material-icons-picture_as_pdf:before {
	content: "\e415"
}

.material-icons-picture_in_picture:before {
	content: "\e8aa"
}

.material-icons-pin_drop:before {
	content: "\e55e"
}

.material-icons-place:before {
	content: "\e55f"
}

.material-icons-play_arrow:before {
	content: "\e037"
}

.material-icons-play_circle_filled:before {
	content: "\e038"
}

.material-icons-play_circle_outline:before {
	content: "\e039"
}

.material-icons-play_for_work:before {
	content: "\e906"
}

.material-icons-playlist_add:before {
	content: "\e03b"
}

.material-icons-plus_one:before {
	content: "\e800"
}

.material-icons-poll:before {
	content: "\e801"
}

.material-icons-polymer:before {
	content: "\e8ab"
}

.material-icons-portable_wifi_off:before {
	content: "\e0ce"
}

.material-icons-portrait:before {
	content: "\e416"
}

.material-icons-power:before {
	content: "\e63c"
}

.material-icons-power_input:before {
	content: "\e336"
}

.material-icons-power_settings_new:before {
	content: "\e8ac"
}

.material-icons-present_to_all:before {
	content: "\e0df"
}

.material-icons-print:before {
	content: "\e8ad"
}

.material-icons-public:before {
	content: "\e80b"
}

.material-icons-publish:before {
	content: "\e255"
}

.material-icons-query_builder:before {
	content: "\e8ae"
}

.material-icons-question_answer:before {
	content: "\e8af"
}

.material-icons-queue:before {
	content: "\e03c"
}

.material-icons-queue_music:before {
	content: "\e03d"
}

.material-icons-radio:before {
	content: "\e03e"
}

.material-icons-radio_button_checked:before {
	content: "\e837"
}

.material-icons-radio_button_unchecked:before {
	content: "\e836"
}

.material-icons-rate_review:before {
	content: "\e560"
}

.material-icons-receipt:before {
	content: "\e8b0"
}

.material-icons-recent_actors:before {
	content: "\e03f"
}

.material-icons-redeem:before {
	content: "\e8b1"
}

.material-icons-redo:before {
	content: "\e15a"
}

.material-icons-refresh:before {
	content: "\e5d5"
}

.material-icons-remove:before {
	content: "\e15b"
}

.material-icons-remove_circle:before {
	content: "\e15c"
}

.material-icons-remove_circle_outline:before {
	content: "\e15d"
}

.material-icons-remove_red_eye:before {
	content: "\e417"
}

.material-icons-reorder:before {
	content: "\e8fe"
}

.material-icons-repeat:before {
	content: "\e040"
}

.material-icons-repeat_one:before {
	content: "\e041"
}

.material-icons-replay:before {
	content: "\e042"
}

.material-icons-replay_10:before {
	content: "\e059"
}

.material-icons-replay_30:before {
	content: "\e05a"
}

.material-icons-replay_5:before {
	content: "\e05b"
}

.material-icons-reply:before {
	content: "\e15e"
}

.material-icons-reply_all:before {
	content: "\e15f"
}

.material-icons-report:before {
	content: "\e160"
}

.material-icons-report_problem:before {
	content: "\e8b2"
}

.material-icons-restaurant_menu:before {
	content: "\e561"
}

.material-icons-restore:before {
	content: "\e8b3"
}

.material-icons-ring_volume:before {
	content: "\e0d1"
}

.material-icons-room:before {
	content: "\e8b4"
}

.material-icons-rotate_90_degrees_ccw:before {
	content: "\e418"
}

.material-icons-rotate_left:before {
	content: "\e419"
}

.material-icons-rotate_right:before {
	content: "\e41a"
}

.material-icons-router:before {
	content: "\e328"
}

.material-icons-satellite:before {
	content: "\e562"
}

.material-icons-save:before {
	content: "\e161"
}

.material-icons-scanner:before {
	content: "\e329"
}

.material-icons-schedule:before {
	content: "\e8b5"
}

.material-icons-school:before {
	content: "\e80c"
}

.material-icons-screen_lock_landscape:before {
	content: "\e1be"
}

.material-icons-screen_lock_portrait:before {
	content: "\e1bf"
}

.material-icons-screen_lock_rotation:before {
	content: "\e1c0"
}

.material-icons-screen_rotation:before {
	content: "\e1c1"
}

.material-icons-sd_card:before {
	content: "\e623"
}

.material-icons-sd_storage:before {
	content: "\e1c2"
}

.material-icons-search:before {
	content: "\e8b6"
}

.material-icons-security:before {
	content: "\e32a"
}

.material-icons-select_all:before {
	content: "\e162"
}

.material-icons-send:before {
	content: "\e163"
}

.material-icons-settings:before {
	content: "\e8b8"
}

.material-icons-settings_applications:before {
	content: "\e8b9"
}

.material-icons-settings_backup_restore:before {
	content: "\e8ba"
}

.material-icons-settings_bluetooth:before {
	content: "\e8bb"
}

.material-icons-settings_brightness:before {
	content: "\e8bd"
}

.material-icons-settings_cell:before {
	content: "\e8bc"
}

.material-icons-settings_ethernet:before {
	content: "\e8be"
}

.material-icons-settings_input_antenna:before {
	content: "\e8bf"
}

.material-icons-settings_input_component:before {
	content: "\e8c0"
}

.material-icons-settings_input_composite:before {
	content: "\e8c1"
}

.material-icons-settings_input_hdmi:before {
	content: "\e8c2"
}

.material-icons-settings_input_svideo:before {
	content: "\e8c3"
}

.material-icons-settings_overscan:before {
	content: "\e8c4"
}

.material-icons-settings_phone:before {
	content: "\e8c5"
}

.material-icons-settings_power:before {
	content: "\e8c6"
}

.material-icons-settings_remote:before {
	content: "\e8c7"
}

.material-icons-settings_system_daydream:before {
	content: "\e1c3"
}

.material-icons-settings_voice:before {
	content: "\e8c8"
}

.material-icons-share:before {
	content: "\e80d"
}

.material-icons-shop:before {
	content: "\e8c9"
}

.material-icons-shop_two:before {
	content: "\e8ca"
}

.material-icons-shopping_basket:before {
	content: "\e8cb"
}

.material-icons-shopping_cart:before {
	content: "\e8cc"
}

.material-icons-shuffle:before {
	content: "\e043"
}

.material-icons-signal_cellular_4_bar:before {
	content: "\e1c8"
}

.material-icons-signal_cellular_connected_no_internet_4_bar:before {
	content: "\e1cd"
}

.material-icons-signal_cellular_no_sim:before {
	content: "\e1ce"
}

.material-icons-signal_cellular_null:before {
	content: "\e1cf"
}

.material-icons-signal_cellular_off:before {
	content: "\e1d0"
}

.material-icons-signal_wifi_4_bar:before {
	content: "\e1d8"
}

.material-icons-signal_wifi_4_bar_lock:before {
	content: "\e1d9"
}

.material-icons-signal_wifi_off:before {
	content: "\e1da"
}

.material-icons-sim_card:before {
	content: "\e32b"
}

.material-icons-sim_card_alert:before {
	content: "\e624"
}

.material-icons-skip_next:before {
	content: "\e044"
}

.material-icons-skip_previous:before {
	content: "\e045"
}

.material-icons-slideshow:before {
	content: "\e41b"
}

.material-icons-smartphone:before {
	content: "\e32c"
}

.material-icons-sms:before {
	content: "\e625"
}

.material-icons-sms_failed:before {
	content: "\e626"
}

.material-icons-snooze:before {
	content: "\e046"
}

.material-icons-sort:before {
	content: "\e164"
}

.material-icons-sort_by_alpha:before {
	content: "\e053"
}

.material-icons-space_bar:before {
	content: "\e256"
}

.material-icons-speaker:before {
	content: "\e32d"
}

.material-icons-speaker_group:before {
	content: "\e32e"
}

.material-icons-speaker_notes:before {
	content: "\e8cd"
}

.material-icons-speaker_phone:before {
	content: "\e0d2"
}

.material-icons-spellcheck:before {
	content: "\e8ce"
}

.material-icons-star:before {
	content: "\e838"
}

.material-icons-star_border:before {
	content: "\e83a"
}

.material-icons-star_half:before {
	content: "\e839"
}

.material-icons-stars:before {
	content: "\e8d0"
}

.material-icons-stay_current_landscape:before {
	content: "\e0d3"
}

.material-icons-stay_current_portrait:before {
	content: "\e0d4"
}

.material-icons-stay_primary_landscape:before {
	content: "\e0d5"
}

.material-icons-stay_primary_portrait:before {
	content: "\e0d6"
}

.material-icons-stop:before {
	content: "\e047"
}

.material-icons-storage:before {
	content: "\e1db"
}

.material-icons-store:before {
	content: "\e8d1"
}

.material-icons-store_mall_directory:before {
	content: "\e563"
}

.material-icons-straighten:before {
	content: "\e41c"
}

.material-icons-strikethrough_s:before {
	content: "\e257"
}

.material-icons-style:before {
	content: "\e41d"
}

.material-icons-subject:before {
	content: "\e8d2"
}

.material-icons-subtitles:before {
	content: "\e048"
}

.material-icons-supervisor_account:before {
	content: "\e8d3"
}

.material-icons-surround_sound:before {
	content: "\e049"
}

.material-icons-swap_calls:before {
	content: "\e0d7"
}

.material-icons-swap_horiz:before {
	content: "\e8d4"
}

.material-icons-swap_vert:before {
	content: "\e8d5"
}

.material-icons-swap_vertical_circle:before {
	content: "\e8d6"
}

.material-icons-switch_camera:before {
	content: "\e41e"
}

.material-icons-switch_video:before {
	content: "\e41f"
}

.material-icons-sync:before {
	content: "\e627"
}

.material-icons-sync_disabled:before {
	content: "\e628"
}

.material-icons-sync_problem:before {
	content: "\e629"
}

.material-icons-system_update:before {
	content: "\e62a"
}

.material-icons-system_update_alt:before {
	content: "\e8d7"
}

.material-icons-tab:before {
	content: "\e8d8"
}

.material-icons-tab_unselected:before {
	content: "\e8d9"
}

.material-icons-tablet:before {
	content: "\e32f"
}

.material-icons-tablet_android:before {
	content: "\e330"
}

.material-icons-tablet_mac:before {
	content: "\e331"
}

.material-icons-tag_faces:before {
	content: "\e420"
}

.material-icons-tap_and_play:before {
	content: "\e62b"
}

.material-icons-terrain:before {
	content: "\e564"
}

.material-icons-text_format:before {
	content: "\e165"
}

.material-icons-textsms:before {
	content: "\e0d8"
}

.material-icons-texture:before {
	content: "\e421"
}

.material-icons-theaters:before {
	content: "\e8da"
}

.material-icons-thumb_down:before {
	content: "\e8db"
}

.material-icons-thumb_up:before {
	content: "\e8dc"
}

.material-icons-thumbs_up_down:before {
	content: "\e8dd"
}

.material-icons-time_to_leave:before {
	content: "\e62c"
}

.material-icons-timelapse:before {
	content: "\e422"
}

.material-icons-timer:before {
	content: "\e425"
}

.material-icons-timer_10:before {
	content: "\e423"
}

.material-icons-timer_3:before {
	content: "\e424"
}

.material-icons-timer_off:before {
	content: "\e426"
}

.material-icons-toc:before {
	content: "\e8de"
}

.material-icons-today:before {
	content: "\e8df"
}

.material-icons-toll:before {
	content: "\e8e0"
}

.material-icons-tonality:before {
	content: "\e427"
}

.material-icons-toys:before {
	content: "\e332"
}

.material-icons-track_changes:before {
	content: "\e8e1"
}

.material-icons-traffic:before {
	content: "\e565"
}

.material-icons-transform:before {
	content: "\e428"
}

.material-icons-translate:before {
	content: "\e8e2"
}

.material-icons-trending_down:before {
	content: "\e8e3"
}

.material-icons-trending_flat:before {
	content: "\e8e4"
}

.material-icons-trending_up:before {
	content: "\e8e5"
}

.material-icons-tune:before {
	content: "\e429"
}

.material-icons-turned_in:before {
	content: "\e8e6"
}

.material-icons-turned_in_not:before {
	content: "\e8e7"
}

.material-icons-tv:before {
	content: "\e333"
}

.material-icons-undo:before {
	content: "\e166"
}

.material-icons-unfold_less:before {
	content: "\e5d6"
}

.material-icons-unfold_more:before {
	content: "\e5d7"
}

.material-icons-usb:before {
	content: "\e1e0"
}

.material-icons-verified_user:before {
	content: "\e8e8"
}

.material-icons-vertical_align_bottom:before {
	content: "\e258"
}

.material-icons-vertical_align_center:before {
	content: "\e259"
}

.material-icons-vertical_align_top:before {
	content: "\e25a"
}

.material-icons-vibration:before {
	content: "\e62d"
}

.material-icons-video_library:before {
	content: "\e04a"
}

.material-icons-videocam:before {
	content: "\e04b"
}

.material-icons-videocam_off:before {
	content: "\e04c"
}

.material-icons-view_agenda:before {
	content: "\e8e9"
}

.material-icons-view_array:before {
	content: "\e8ea"
}

.material-icons-view_carousel:before {
	content: "\e8eb"
}

.material-icons-view_column:before {
	content: "\e8ec"
}

.material-icons-view_comfy:before {
	content: "\e42a"
}

.material-icons-view_compact:before {
	content: "\e42b"
}

.material-icons-view_day:before {
	content: "\e8ed"
}

.material-icons-view_headline:before {
	content: "\e8ee"
}

.material-icons-view_list:before {
	content: "\e8ef"
}

.material-icons-view_module:before {
	content: "\e8f0"
}

.material-icons-view_quilt:before {
	content: "\e8f1"
}

.material-icons-view_stream:before {
	content: "\e8f2"
}

.material-icons-view_week:before {
	content: "\e8f3"
}

.material-icons-vignette:before {
	content: "\e435"
}

.material-icons-visibility:before {
	content: "\e8f4"
}

.material-icons-visibility_off:before {
	content: "\e8f5"
}

.material-icons-voice_chat:before {
	content: "\e62e"
}

.material-icons-voicemail:before {
	content: "\e0d9"
}

.material-icons-volume_down:before {
	content: "\e04d"
}

.material-icons-volume_mute:before {
	content: "\e04e"
}

.material-icons-volume_off:before {
	content: "\e04f"
}

.material-icons-volume_up:before {
	content: "\e050"
}

.material-icons-vpn_key:before {
	content: "\e0da"
}

.material-icons-vpn_lock:before {
	content: "\e62f"
}

.material-icons-wallpaper:before {
	content: "\e1bc"
}

.material-icons-warning:before {
	content: "\e002"
}

.material-icons-watch:before {
	content: "\e334"
}

.material-icons-wb_auto:before {
	content: "\e42c"
}

.material-icons-wb_cloudy:before {
	content: "\e42d"
}

.material-icons-wb_incandescent:before {
	content: "\e42e"
}

.material-icons-wb_iridescent:before {
	content: "\e436"
}

.material-icons-wb_sunny:before {
	content: "\e430"
}

.material-icons-wc:before {
	content: "\e63d"
}

.material-icons-web:before {
	content: "\e051"
}

.material-icons-whatshot:before {
	content: "\e80e"
}

.material-icons-widgets:before {
	content: "\e1bd"
}

.material-icons-wifi:before {
	content: "\e63e"
}

.material-icons-wifi_lock:before {
	content: "\e1e1"
}

.material-icons-wifi_tethering:before {
	content: "\e1e2"
}

.material-icons-work:before {
	content: "\e8f9"
}

.material-icons-wrap_text:before {
	content: "\e25b"
}

.material-icons-youtube_searched_for:before {
	content: "\e8fa"
}

.material-icons-zoom_in:before {
	content: "\e8ff"
}

.material-icons-zoom_out:before {
	content: "\e900"
}
